<template>
  <div v-if="show" class="box">
    <div class="box-title has-top-radius">
      Anna palautetta
    </div>
    <div v-if="!success" class="no-top-radius content">
      <p>Palautteesi on meille hyvin tärkeää, jotta voisimme palvella sinua mahdollisimman hyvin!</p>
      <div class="field">
        <div class="control">
          <textarea
            key="message"
            v-model="message"
            v-validate="'required'"
            name="message"
            class="textarea"
            placeholder="Kirjoita palautetta ..."
            rows="4"
          />
        </div>
      </div>
      <p v-if="data.showError" class="has-text-danger">Palautelaatikko on tyhjä.</p>
      <button :disabled="data.submitLoading" class="button is-primary is-rounded" type="button" @click="submit">
        <span>Lähetä</span><span v-if="data.submitLoading" class="icon"><font-awesome-icon :icon="['fas', 'spinner-third']" spin /></span>
      </button>
    </div>
    <div v-else class="no-top-radius content">
      <p class="has-text-rlgreen is-size-4 has-text-weight-bold">Kiitos palautteestasi</p>
      <p>Palautteesi on meille hyvin tärkeää, jotta voisimme palvella sinua mahdollisimman hyvin!</p>
    </div>
  </div>
</template>

<script>
// @todo show only when granted
import _ from 'lodash'

export default {
  name: 'Feedback',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      data: {
        submitLoading: false,
        showError: false
      },
      message: '',
      show: false,
      success: false
    }
  },
  async created () {
    const result = await this.$axios.$get( '/v1/oma/?action=feedback&json' )
    if ( result.show ) this.show = true
  },
  methods: {
    async submit () {
      this.data.submitLoading = true
      const validateResult = await this.$validator.validate()
      if ( validateResult ) {
        try {
          const result = await this.$axios.$get( '/v1/oma/?action=feedback&method=add&json', {
            params: {
              viesti: this.message
            }
          } )
          if ( _.has( result, 'success' ) ) {
            this.success = true
            this.$ga.event( { eventCategory: 'Oma', eventAction: 'Added', eventLabel: 'Feedback' } )
          }
          this.$gtag.event( 'Added', {
            event_category: 'Oma',
            event_label: 'Feedback'
          } )
        }
        catch ( error ) {
          this.$logger.error( error )
        }
      }
      else {
        this.showError = true
      }
      this.data.submitLoading = false
    }
  }
}
</script>
