<template>
  <div class="field">
    <div class="control">
      <input
        :id="`checkbox_${name}`"
        :name="name"
        :checked="hasValue ? true : false"
        :class="{ 'is-danger': error, 'is-static': isStatic }"
        :value="hasValue ? (stringValue ? stringValue : value) : false"
        :disabled="isStatic"
        class="is-checkradio"
        type="checkbox"
        @change="$emit('input', $event.target.checked && stringValue || $event.target.checked)"
      >
      <label :for="`checkbox_${name}`">
        <slot />
      </label>
    </div>
    <slot name="help" />
    <p v-if="error" class="help is-danger">{{ error }}</p>
  </div>
</template>

<script>

export default {
  $_veeValidate: {
    value () {
      return this.value
    },
    name () {
      return this.name
    },
    events: 'input'
  },
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: [Boolean, String],
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    stringValue: {
      type: String,
      default: ''
    },
    isStatic: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasValue () {
      return Boolean( this.value ) && this.value !== 'false' && this.value !== '0'
    }
  }
}
</script>
