<script>
import faq from '~/components/layout/faq'

export default {
  name: 'JarjestelylainaaFAQAccordion',
  extends: faq,
  data () {
    return {
      questions: [
        {
          title: 'Kenelle järjestelylaina sopii?',
          content:
            `Järjestelylaina sopii henkilöille, joille on kertynyt useita lainoja ja luottoja. Järjestelylainaan voi
            yhdistää kaikki luottokorttivelat, osamaksusopimukset, pienlainat ja pikavipit. Järjestelylaina voi tuoda
            merkittäviä säästöjä erilaisten lainakulujen, palkkioiden ja tilinhoitomaksujen poistuessa. Järjestelylaina
            mahdollistaa myös edullisen koron yhdelle suuremmalle summalle useamman eri pienlainan sijaan.`
        },
        {
          title: 'Mitä hyötyä on järjestelylainasta?',
          content:
            `Järjestelylainan avulla yhdistät monta pientä lainaa yhdeksi. Pienissä lainoissa on yleensä korkeampi korko
            kuin suuremmissa. Järjestelylainaan voit saada selvästi alhaisemman koron kuin pieniin lainoihin. Lainan
            koron lisäksi myös kuukausittain maksettavat tilinhoitomaksut tai vastaavat kuukausikulut maksetaan yleensä
            saman suuruisina, oli kyseessä sitten pienempi tai suurempi laina. Järjestelylainasta maksetaan
            luonnollisesti vain yksi tilinhoitomaksu ja tämä voi tuoda merkittävänkin säästön. Lisäksi kun maksettavana
            on vain yksi lasku, säästät myös aikaasi – et joudu muistamaan erikseen useita eräpäiviä ja elämän ja
            raha-asioiden hallinta helpottuu, kun lyhennettävänä on vain yksi laina.`
        },
        {
          title: 'Kuka voi hakea järjestelylainaa Rahalaitoksen kautta?',
          content:
            `Perusvaatimukset järjestelylainan saamiseen Rahalaitoksen kumppaneilta ovat säännölliset eläke– tai
            palkkatulot sekä puhtaat luottotiedot. Iältään lainanhakijan tulee olla vähintään 20-vuotias. Jos sinulla
            on maksuhäiriöitä tai et saa tulojesi vuoksi järjestelylainaa Rahalaitoksen kumppaneilta, voit hakea
            järjestelylainaa myös Takuusäätiöltä tai kunnan sosiaaliviraston avulla.`
        },
        {
          title: 'Miten korkolain muutos vaikuttaa järjestelylainoihin?',
          content:
            `Kulutusluottojen korkoja säätelevää lakia on muutettu syyskuussa 2019 ja uudestaan kesäkuussa 2020.
            Vanhojen lainojen korkoja laki ei koske ja nyt on erityisen kannattavaa ottaa järjestelylaina ja yhdistää
            vanhat lainat yhdeksi. Nyt myös isompien lainojen (ja vanhempien järjestelylainojen) korot ovat laskeneet
            aiempaan verrattuna ja uudelleen kilpailuttamisella voi saada merkittäviäkin säästöjä korkokaton ansiosta.`
        },
        {
          title: 'Mitä kannattaa huomioida järjestelylainaa otettaessa?',
          content:
            `Kun vanhat lainat maksetaan pois järjestelylainalla, kannattaa miettiä mikä on oikea kuukausierä
            maksettavaksi niin että elämän muista menoista selviää järkevästi. Joskus laina-aika pitenee
            järjestelylainaa otettaessa. Jos maksettava kuukausierä pienenee, lainan maksaminen kestää pitempään. Usein
            tämä on kuitenkin pidemmällä tähtäimellä hyvä asia, jotta rahaa jää kuukausittain käyttöön enemmän. Kun
            pakolliset menot saa kuukausittain hoidettua, pystyy elämän suunnitteluun ja oman talouden parantamiseen
            keskittymään paremmin. Jotta pankilta tai rahoitusyhtiöltä saa järjestelylainaa, on luottotietojen oltava
            kunnossa, ja hakijalla on oltava säännölliset kuukausitulot. Me Rahalaitoksella kilpailutamme
            järjestelylainoja usealta eri pankilta. Vertailemalla järjestelylainoja kauttamme pidät huolen siitä, että
            löydät parhaimman mahdollisen lainan, jolla yhdistellä kaikki velkasi.`
        }
      ]
    }
  }
}
</script>
