<template>
  <div class="top-points-section surveymonkey">
    <div class="top-points-section-content">
      <div class="top-points-section-block top-points-icon">
        <font-awesome-icon :icon="['fal', 'comment-alt-smile']" size="3x" :style="{ color: '#6bd100', height: 'auto' }" fixed-width />
      </div>
      <div class="top-points-section-block">
        <h3>
          <p class="subtitle is-6 has-text-weight-bold">{{ title }}</p>
          <p class="title is-5 has-text-weight-bold">
            <font-awesome-icon :icon="['fas', 'star']" />
            <font-awesome-icon :icon="['fas', 'star']" />
            <font-awesome-icon :icon="['fas', 'star']" />
            <font-awesome-icon :icon="['fas', 'star']" />
            <font-awesome-icon :icon="['fas', 'star-half-alt']" />
          </p>
          <p class="has-text-white subtitle is-7">4,46/7503 arviota*</p>
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: 'Asiakkaamme arvioivat',
      type: String
    }
  }
}
</script>
