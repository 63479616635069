<template>
  <div class="app">
    <div class="columns">
      <div class="column">
        <p class="title is-4 has-text-rlgreen">Kiitos lainahakemuksesta</p>
        <div class="content">
          <p>
            Haemme sinulle lainatarjouksia. Siirryt
            <b>{{ seconds }} sekunnin</b> päästä Oma Rahalaitos -palveluun,
            jossa voit verrata saamiasi tarjouksia.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      seconds: 6
    }
  },
  mounted () {
    this.$nextTick( () => {
      this.$el.scrollIntoView( {
        behavior: 'smooth',
        block: 'start'
      } )
      // Countdown for redirect
      setInterval( () => {
        if ( this.seconds === 1 ) {
          if ( this.$route.path.startsWith( '/cc-new' ) ) {
            this.$router.push( '/cc-new' )
          }
          else {
            this.$router.push( 'oma' )
          }
        }
        else {
          this.seconds -= 1
        }
      }, 1000 )
    } )
  }
}
</script>
