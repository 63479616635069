<template>
  <div class="search-application app">
    <form-input
      key="form.orderID"
      v-model.trim="form.orderID"
      v-validate="'required|lengths:20,56|alpha_num'"
      :error="errors.first('orderID')"
      name="orderID"
      input-class="has-text-centered"
      type="text"
      placeholder="Hakemuksen ID"
    />
    <button
      class="button is-rounded"
      type="button"
      @click="clear"
    >
      Tyhjennä
    </button>
    <button
      :disabled="data.submitDisabled || data.submitLoading"
      class="button is-primary is-rounded"
      style="float: right"
      type="button"
      @click="submit"
    >
      <span>Hae hakemus</span><span v-if="data.submitLoading" class="icon"><font-awesome-icon :icon="['fas', 'spinner-third']" spin /></span>
    </button>
  </div>
</template>

<script lang="js">
import _ from 'lodash'
import Noty from 'noty'

export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    formInput: () => import( '~/components/form/input' )
  },
  data () {
    return {
      data: {
        submitDisabled: false,
        submitLoading: false
      },
      form: {
        orderID: ''
      }
    }
  },
  methods: {
    /**
     * Get application data and fill it
     */
    async submit () {
      if ( !this.applicationVisible() ) {
        return
      }
      Noty.closeAll()
      this.data.submitLoading = true
      const validateResult = await this.$validator.validate()
      if ( !validateResult ) {
        this.data.submitLoading = false
        return
      }
      let result = {}
      try {
        result = await this.$axios.$get( '/v1/cc/api', {
          params: {
            search: this.form.orderID
          }
        } )
      }
      catch ( error ) {
        this.$logger.error( error )
      }
      if ( _.has( result, 'app' ) ) {
        // change yearly income into monthly
        result.app.bruttotulot = ( result.app.bruttotulot / 12 ).toFixed( 2 )
        result.app.nettotulot = ( result.app.nettotulot / 12 ).toFixed( 2 )
        // Fill application
        document.getElementsByClassName( 'loan-application' )[0].__vue__.mergeFromVuex()
        const application = document.getElementsByClassName( 'loan-application' )[0].__vue__
        // Clear the form first (if there would be some other data.)
        await application.clearForm()
        // Merge fields
        await application.mergeFormData( {
          ...result.app,
          firstStep: true,
          ehdot: true,
          tarkistus: true
        } )
        if ( _.has( result, 'duplicate' ) && result.duplicate ) {
          this.$noty( {
            layout: 'bottom',
            type: 'warning',
            text: 'Asiakkaalla on jo aktiivinen hakemus. Siirtykää seuraavaan.',
            timeout: 0
          } )
        }
        else {
          this.$noty( {
            layout: 'bottom',
            type: 'success',
            text: 'Hakemus on täytetty sivulle.',
            timeout: 5000
          } )
        }
      }
      if ( _.has( result, 'error' ) ) {
        this.$noty( {
          layout: 'bottom',
          type: 'error',
          text: 'Hakemuksen hakeminen epäonnistui.',
          timeout: 5000
        } )
      }
      this.data.submitLoading = false
    },
    /**
     * Clear application and ID field
     */
    clear () {
      if ( !this.applicationVisible() ) {
        return
      }
      this.form.orderID = ''
      // Clear the form first (if there would be some other data.)
      document.getElementsByClassName( 'loan-application' )[0].__vue__.clearForm()
    },
    /**
     * Check's if application is visible.
     * Shows notification otherwise
     */
    applicationVisible () {
      const result = Boolean( document.querySelector( '.loan-application.application' ) )
      // Send notification
      if ( !result ) {
        this.$noty( {
          layout: 'bottom',
          type: 'warning',
          text: 'Hakemusta ei ole näkyvissä.',
          timeout: 5000
        } )
      }
      return result
    }
  }
}
</script>

<style lang="scss">
.search-application {
  margin-bottom: 1em;
}
</style>
