<script>
import faq from '~/components/layout/faq'

export default {
  extends: faq,
  data () {
    return {
      questions: [
        {
          title: 'Kuka voi saada lainaa 70000 euroa?',
          content:
            `Kumppanimme ovat vastuullisia luotonantajia eivätkä he tahdo, että joudut
            taloudellisiin vaikeuksiin lainaamisen takia. Tästä syystä hakijoiden täytyy kumpienkin
            täyttää seuraavat ehdot, jotta voivat saada lainaa 70000 euroa: teillä on
            kummallakin yli 600 € kuukausitulot, olette vähintään 20-vuotiaia ja teillä
            ei ole julkisia maksuhäiriömerkintöjä.`
        },
        {
          title: 'Kuinka nopeasti voin saada lainaa 70000 euroa?',
          content:
            `Koska Rahalaitos ei ole luotonmyöntäjä, emme valitettavasti voi vaikuttaa siihen
            koska saatte rahat tilille. Me toimitamme hakemuksesi sähköisesti suoraan pankeille
            ja saatte luottopäätöksen heti täytettyänne hakemuksen. Useimmat kumppaneistamme
            lupaavat, että parhaimmassa tapauksessa saatte rahat tilille jopa saman päivän aikana.`
        },
        {
          title: 'Voinko hakea lainaa 70000 euroa maksutta?',
          content:
            `Rahalaitoksen palvelussa lainan hakeminen on aina täysin maksutonta. Ette myöskään
            sitoudu vielä mihinkään, kun täytätte lainahakemuksen ja haette lainaa palvelussamme.
            Voitte rauhassa vertailla saamiasi tarjouksia ja halutessasi nostaa itse valitsemanne lainan. `
        },
        {
          title: 'Voiko saada 70000 euroa lainaa ilman säästöjä?',
          content:
            `Rahalaitoksen kumppanina toimivat lainantarjoajat eivät vaadi lainan saamiseen
            säästöjä tai muita vakuuksia. Lainaa voi siis saada vaikka teillä ei olisi säästöjä.`
        }
      ]
    }
  }
}
</script>
