<template>
  <div class="content">
    <breadcrumb
      :path="{'Yhdistä lainat': '/yhdista-lainat', 'Hyötynnä lainojen korkokatto': ''}"
    />
    <img style="width: 100%;" :src="require(`~/assets/img/subpage/${this.$route.path.substring(1)}.jpg`)" :alt="this.$route.path.substring(1)">
    <h1>Essi ja Jere hyödynsivät korkokaton | Säästävät lähes 400</h1>
    <p>
      Kun <b>Essi</b>, 28 ja <b>Jere</b>, 29, ostivat ensimmäisen yhteisen kotinsa Vantaalta, päättivät he käydä läpi koko taloustilanteensa. He halusivat selvittää, voisivatko karsia niin sanottuja turhia kuluja, jotta rahaa jäisi enemmän muuhun elämään ja uuden kodin laittamiseen.
    </p>
    <p>
      Molemmat käyvät töissä, mutta koska asuminen pääkaupunkiseudulla on kallista ja nielee suuren osa tuloista, oli heille vuosien varrella kertynyt useampia pieniä luottoja erilaisista hankinnoista, kuten autosta, huonekaluista ja kodinkoneista.
    </p>
    <p>
      Suomessa tuli syyskuussa 2019 voimaan laki, joka rajaa vakuudettomien kulutusluottojen nimelliskoron 20 prosenttiin ja vuotuiset lainanhoitokulut 150 euroon. Essin ja Jeren lainat oli kuitenkin otettu ennen tätä, ja niiden korot olivatkin osin reippaasti yli nykyisen korkokaton.
    </p>
    <p>
      Yhteensä pariskunnalla oli seitsemän eri kulutusluottoa, joiden yhteenlaskettu summa oli 23 000 euroa. Kuukausittaisia lyhennyksiä he maksoivat yhteensä 843 euroa.
    </p>
    <p>
      Uusi asunto vaati keittiöremonttia, joten Essi ja Jere päättivät hakea <nuxt-link to="/yhdista-lainat">yhdistelylainaa</nuxt-link>, jolla maksaisivat pois vanhat luotot ja rahoittaisivat remontin. Suomen johtavan lainanvälittäjän Rahalaitoksen kautta he saivat nopeasti ja helposti kilpailutettua tarvitsemansa 30 000 euron yhdistelylainan.
    </p>
    <h2>Suurempi laina, pienemmät menot</h2>
    <p>
      Yhdellä hakemuksella <nuxt-link to="/">Rahalaitos</nuxt-link> kilpailuttaa lainan jopa 25 yhteistyöpankillaan. Niiden joukosta Essille ja Jerelle löytyi nopeasti hyvä tarjous. 9,47 %, eli reilusti alle korkokaton. Laina-ajaksi otettiin 8 vuotta.
    </p>
    <p>
      Nyt Essi ja Jere maksavat uudesta yhteisestä lainastaan 452 euroa kuussa, eli he säästävät joka kuukausi 391 euroa, vaikka lainan määrä kasvoi keittiöremontin myötä. Vuodessa säästöjä kertyy 4 692 euroa.
    </p>
    <p>
      Essi ja Jere ovat todella tyytyväisiä, että tajusivat yhdistää ja kilpailuttaa vanhat lainansa.
    </p>
    <span class="miniform-header"> Lainan kilpailutus 70 000 € asti </span>
    <mini-form />
    <share text="Essi ja Jere hyödynsivät korkokaton | Säästävät lähes 400" />
  </div>
</template>

<script>
export default {
  layout: 'articles',
  name: 'EssiJaJereHyDynsivTLainojenKorkokaton',
  components: {
    miniForm: () => import( '~/components/subpage/miniform' ),
    share: () => import( '~/components/subpage/share' ),
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  head () {
    return {
      title: 'Essi ja Jere hyödynsivät korkokaton | Säästävät lähes 400',
      meta: [
        { hid: 'description', name: 'description', content: 'Essi ja Jere hyödynsivät korkolain muutoksen ja yhdistivät luotot yhdeksi lainaksi. Sen myötä säästävät 400 euroa kuukaudessa. Kannatti kilpailuttaa!' }
      ]
    }
  }
}
</script>
