






























































































import { defineComponent, useStore, computed } from '@nuxtjs/composition-api'
import { RootState } from '~/store'

export default defineComponent( {
  components: {
    logoSvg: () => import( '~/assets/img/logo.svg?inline' )
  },
  setup () {
    const store = useStore<RootState>()
    return {
      applicationVisibility: computed( () => store.state.loanApplication.formVisible )
    }
  },
  data () {
    return {
      toggleNavbar: false,
      hover: false
    }
  }
} )
