<template>
  <div class="loan-picker">
    <div class="columns">
      <div class="column is-4">
        <form-select
          :key="`Loans-${prefix}-selectCount`"
          v-model.number="selectCount"
          v-validate="'required'"
          :name="name"
          :error="errors.first(name)"
          label="Lainojen lukumäärä"
        >
          <option value="0">Ei lainoja</option>
          <option v-for="option of maxLoans" :key="option" :value="option">{{ option }}</option>
        </form-select>
      </div>
    </div>
    <div v-for="(loan, index) of count" :key="index" class="loan-picker__options columns is-mobile is-multiline">
      <div class="column is-12-mobile is-4-desktop">
        <div class="field">
          <div class="control has-float-label">
            <div class="select has-disabled is-fullwidth">
              <form-select
                :key="`Loans-${prefix}-${index}-loanType`"
                v-model="loans[index].loanType"
                v-validate="'required'"
                :name="`Loans[${prefix}][${index}][loanType]`"
                :error="errors.first(`Loans[${prefix}][${index}][loanType]`)"
                label="Lainan Tyyppi"
              >
                <option value="Creditcard">Luottokorttilaina</option>
                <option value="Mortgage">Asuntolaina</option>
                <option value="Student">Opintolaina</option>
                <option value="Car">Auto-/venelaina</option>
                <option value="Other">Muu laina</option>
              </form-select>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-6-mobile">
        <form-input
          :key="`Loans-${prefix}-${index}-totalAmount`"
          v-model.number="loans[index].totalAmount"
          v-validate="'required|numeric|max:7'"
          :error="errors.first(`Loans[${prefix}][${index}][totalAmount]`)"
          :name="`Loans[${prefix}][${index}][totalAmount]`"
          label="Lainan Saldo"
          tooltip="Jäljellä oleva laina. Ilmoita vain oma osuus."
        />
      </div>
      <div class="column is-6-mobile">
        <form-input
          :key="`Loans-${prefix}-${index}-monthlyAmount`"
          v-model.number="loans[index].monthlyAmount"
          v-validate="'required|numeric|max:5'"
          :error="errors.first(`Loans[${prefix}][${index}][monthlyAmount]`)"
          :name="`Loans[${prefix}][${index}][monthlyAmount]`"
          label="Kuukausierä"
          tooltip="Ilmoita vain oma osuus."
        />
      </div>
      <div v-if="refinance" class="column is-one-fifth-desktop">
        <form-switch
          v-if="loans[index].loanType !== 'Mortgage'"
          :key="`Loans-${prefix}-${index}-refinance`"
          v-model="loans[index].refinance"
          v-validate="'required'"
          :error="errors.first(`Loans[${prefix}][${index}][refinance]`)"
          :name="`Loans[${prefix}][${index}][refinance]`"
          label="Yhdistätkö lainan"
        />
      </div>
      <input
        :key="`Loans-${prefix}-${index}-loanNumber`"
        v-model="loans[index].loanNumber"
        v-validate="'required'"
        :name="`Loans[${prefix}][${index}][loanNumber]`"
        type="hidden"
      >
    </div>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  components: {
    formInput: () => import( '~/components/form/input' ),
    formSelect: () => import( '~/components/form/select' ),
    formSwitch: () => import( '~/components/form/switch' )
  },
  props: {
    prefix: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'LoanPicker'
    },
    maxLoans: {
      type: Number,
      default: 11
    },
    value: {
      type: Array,
      default () {
        return []
      }
    },
    refinance: {
      type: Boolean,
      default: true
    },
    count: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      loans: [],
      selectCount: this.count
    }
  },
  watch: {
    selectCount () {
      this.$emit( 'countChange', this.selectCount )
    },
    loans: {
      /**
       * @description
       */
      handler () {
        this.$emit( 'input', this.loans.slice( 0, this.count ) )
      },
      deep: true
    }
  },
  /**
   * Populate loans array
   */
  created () {
    this.loans = this.getLoanArray()
  },
  methods: {
    /**
     * Generates loans array
     * @returns {array}
     */
    getLoanArray () {
      const array = this.value.length ? this.value : []
      // Validate existing fields and reset when needed
      for ( let index = array.length - 1; index >= 0; index-- ) {
        if ( typeof array[index].loanNumber !== 'number' ) {
          array[index].loanNumber = index
        }
        if ( typeof array[index].loanType !== 'string' ) {
          array[index].loanType = ''
        }
        if (
          typeof array[index].totalAmount !== 'string' &&
          typeof array[index].totalAmount !== 'number'
        ) {
          array[index].totalAmount = ''
        }
        if (
          typeof array[index].monthlyAmount !== 'string' &&
          typeof array[index].monthlyAmount !== 'number'
        ) {
          array[index].monthlyAmount = ''
        }
        if (
          array[index].refinance !== null &&
          typeof array[index].refinance !== 'boolean'
        ) {
          array[index].refinance = null
        }
      }
      // Add missing loan to fill the array
      for ( let index = array.length; index < this.maxLoans; index++ ) {
        array.push( {
          loanNumber: index,
          loanType: '',
          totalAmount: '',
          monthlyAmount: '',
          refinance: null
        } )
      }
      return array
    }
  }
}
</script>

<style lang="scss">
.loan-picker {
  .is-switch {
    margin-top: -1rem;
    label {
      font-size: $size-7;
    }
  }
  &__options {
    @include mobile {
      border-bottom: 1px solid $grey-lighter;
    }
  }
}
</style>
