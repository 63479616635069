

























import Vue from 'vue'

export default Vue.extend( {
  data () {
    return {
      subpageList: []
    }
  },
  async created () {
    /**
     * Loop through subpages that will be displayed.
     * Check if the subpage image is loadable from the assets folder.
     * If not, then load the page data from strapi and set the image url to that.
     */
    const subpages = this.$store.state.subpage.pages
    for ( let i = 0; i < subpages.length; i++ ) {
      if ( !subpages[i].image ) {
        try {
          require( `~/assets/img/subpage${subpages[i].link}.jpg` )
        }
        catch ( error ) {
          // Remove "/" from link.
          const url = subpages[i].link.slice( 1 )
          const strapi = await this.$axios.$get( `/cms/pages?slug=${url}` )
          // Check if there's data in strapi. Otherwise throw the error.
          if ( strapi.length ) {
            subpages[i].image = `/cms${strapi[0].image.url}`
          }
          else {
            throw error
          }
        }
      }
    }
    this.subpageList = subpages
  },
  methods: {
    getImage ( page: any ) {
      if ( page.image ) {
        return page.image
      }
      else {
        return require( `~/assets/img/subpage${page.link}.jpg` )
      }
    }
  }
} )
